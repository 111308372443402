const storageName = 'userData'
 
export default async function swDev () {

    function urlBase64ToUint8Array (base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
    
        for (let i = 0; i< rawData.length; i++) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
    
    function detemineAppServerKey() {
        let vapidPublicKey = 'BAuiS658QsWQyDdLBEhsk3zDP3sBQShUm09TaCJQoLW9SHQyVhwQ9Wa4dYnFx_l6imMwjpXA8_37CmZEXE75LX8'
        return urlBase64ToUint8Array(vapidPublicKey)
    }
    const swUrl = `${process.env.PUBLIC_URL}/sw.js`
    
    const register = await navigator.serviceWorker.register(swUrl, {
        scope: '/'
    });

    await navigator.serviceWorker.ready
    const subscription = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: detemineAppServerKey(),
    });

    const data = await JSON.parse(localStorage.getItem(storageName))
    const isApp = window.matchMedia('(display-mode: standalone)').matches
    await fetch("/api/subscribe", {
        method: "POST",
        body: JSON.stringify(subscription),
        headers: {
            "Content-Type": "application/json",
            token: data?.token,
            app: isApp,
        }
    })
}