
import {useEffect, useRef} from 'react'
import Move from '../assets/sounds/move.wav'
import Tick from '../assets/sounds/timer.wav'
import Bullet from '../assets/sounds/bullet.wav'

export const useAudio = () => {
    const constextRef = useRef() 
    const moveRef = useRef()
    const tickRef = useRef()
    const bulletRef = useRef()
    useEffect ( () => {
        const context = new (window.AudioContext || window.webkitAudioContext)(); 
        constextRef.current = context
        
        const loadSoundEffects = async (sound, ref) => {
            try {
                const response = await fetch(sound);
                const arrayBuffer = await response.arrayBuffer();
                const audioBuffer = await context.decodeAudioData(arrayBuffer);
                ref.current = audioBuffer
            } catch (error) {
                console.error('Error loading sound effect:', error);
            }
        }
        loadSoundEffects(Bullet, bulletRef)
        loadSoundEffects(Move, moveRef)
        loadSoundEffects(Tick, tickRef)
        
    },[])

    const playMove = () => {
        if (!moveRef.current)  return
        const gainNode = constextRef.current.createGain();
        gainNode.gain.value = 2;
        gainNode.connect(constextRef.current.destination);
        const source = constextRef.current.createBufferSource();
        source.buffer = moveRef.current;
        source.connect(gainNode);
        // source.connect(constextRef.current.destination);
        source.start(0);
    }

    const playBullet = () => {
        console.log('bullet', bulletRef.current)
        if (!bulletRef.current)  return
        // const gainNode = constextRef.current.createGain();
        // gainNode.gain.value = 0.9;
        // gainNode.connect(constextRef.current.destination);
        const source = constextRef.current.createBufferSource();
        source.buffer = bulletRef.current;
        // source.connect(gainNode);
        source.connect(constextRef.current.destination);
        source.start(0);
    }

    const playTick = () => {
        console.log('tick', tickRef.current)
        if (!tickRef.current)  return
        // const gainNode = constextRef.current.createGain();
        // gainNode.gain.value = 0.9;
        // gainNode.connect(constextRef.current.destination);
        const source = constextRef.current.createBufferSource();
        source.buffer = tickRef.current;
        // source.connect(gainNode);
        source.connect(constextRef.current.destination);
        source.start(0);
    }
    
    return {playBullet, playMove, playTick}
}