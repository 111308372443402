import {createContext} from 'react'

function noop() {}
export const AuthContext = createContext({
    token: null,
    userId: null,
    login: noop,
    logout: noop,
    isAuthenticated: false,
    socket: null,
    isAdmin: false,
    sid: null
})